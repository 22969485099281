import $ from "jquery";

// smooth scroll
$(function () {
    $('a[href^="#"]')
        .on('click', function () {
            var adjust = 0;
            var speed = 400;
            var href = $(this).attr('href');
            var target = $(href == '#' || href == '' ? 'html' : href);
            var position = target.offset().top + adjust;
            $('body,html').animate({
                scrollTop: position
            }, speed, 'swing');
            return false;
        });
});

//Window height
$(window).on('load resize', function () {
    var hdr = $('.main-header');
    var hdrHeight = hdr.outerHeight(true);
    var h = $(window).height();
    var cont = $('#kv').outerHeight(true);
    if (cont > h) {
        $('#kv').css('height', h - hdrHeight);
    }

});


//nav
$(function () {
    $('.menuTrigger').on('click', function () {
        $(this).toggleClass('open');
        $('.head-nav').toggleClass('open');
    });
    $('header .nav a').on('click', function () {
        $('.head-nav').toggleClass('open');
    });
});


//Voices carousel
var $owl = $('.voices_list');
$owl.owlCarousel({
    items: 1,
    loop: true,
    center: true,
    dots: true,
    nav: true,
    navText: '',
    autoplayHoverPause: true,
    autoplay: true,
    autoplayTimeout: 10000,
    autoplaySpeed: 2000,

});

$(function () {
    $owl.on('changed.owl.carousel', function (event) {
        var cont = $owl.find('li').eq(event.item.index);
        var hash = cont.data('hash');

        $('.slider_nav li').removeClass('active');
        $('.slider_nav li[data-hash="' + hash + '"]').toggleClass('active');

    });
});


//footer
$(window).on('load scroll', function () {
    let target = $('.floatarea').offset().top;
    let currentPos = $(window).scrollTop() + window.innerHeight;
    if (currentPos > target) {
        $('.floating_btn_wrap').addClass('sticky');
    } else {
        $('.floating_btn_wrap').removeClass('sticky');
    }
});

$(function () {
    var btn = $('.floating_btn_wrap');
    $(window).on('load scroll', function () {
        if ($(this).scrollTop() > 100) {
            btn.addClass('active');
        } else {
            btn.removeClass('active');
        }
    });
});